<template>
  <section v-if="items.length != 0">

    <div class="conversation-subject">{{ subject }}</div>

    <b-form inline class="mt-2">
      <b-button
        v-if="showCloseBtn"
        @click="closeConversation"
        class="no-border"
      >
        <i class="fal fa-times"></i
        ><span class="hidden-mobile">&nbsp;{{ $t("CLOSE") }}</span>
      </b-button>

      
      <span v-if="showCloseBtn">
        <b-button :href="direktLink" class="no-border" target="_blank">
          <i class="fal fa-external-link"></i
          ><span class="hidden-mobile">&nbsp;{{ $t("OPEN_NEW_TAB") }}</span>
        </b-button>
      </span>

      <b-button @click="refreshList" class="no-border"
        ><i class="fal fa-sync"></i
      ></b-button>

      <span class="i-100">&nbsp;</span>
      
      <span>{{ count }} {{ $t('CONVERSATION_MESSAGES')}}</span>
    </b-form>

    <hr class="pb-0 mb-0 mt-2" />

    <div class="m-2" style="position: relative;" :key="conversationKey">
      <div v-for="(item, index) in items" :key="index">
        <b-row no-gutters>
          <b-col md="8" v-if="!isMe(item)">
            <Message
              v-bind:id="'message_' + item.message_uuid.replace(':', '')"
              :ref="item.message_uuid"
              :message_uuid="item.message_uuid"
              :functionbox_uuid="functionbox_uuid"
              :user_uuid="user_uuid"
              @gotoMessage="gotoMessage"
              is-conversation
            ></Message>
          </b-col>
          <b-col md="8" offset-md="4" v-if="isMe(item)">
            <Message
              v-bind:id="'message_' + item.message_uuid.replace(':', '')"
              :message_uuid="item.message_uuid"
              :functionbox_uuid="functionbox_uuid"
              :user_uuid="user_uuid"
              @gotoMessage="gotoMessage"
              is-conversation
            ></Message>
          </b-col>
        </b-row>
      </div>
    </div>
    <b-pagination
      class="mt-2"
      v-model="currentPage"
      :total-rows="count"
      :per-page="limit"
      align="center"
      @input="getResponses"
      pills
    >
    </b-pagination>
  </section>
</template>
<script>
import Message from "@/components/View/Message.vue";
export default {
  props: {
    inbox: {
      type: Boolean,
      validator(value) {
        return value != undefined;
      },
    },
    message_uuid: {
      default: "",
      type: String,
    },
    functionbox_uuid: {
      default: "",
      type: String,
    },
    user_uuid: {
      default: "",
      type: String,
    },
    showCloseBtn: {
      type: Boolean,
      validator(value) {
        return value != undefined;
      },
    },
  },
  components: {
    Message
  },
  data() {
    return {
      firstRun: true,
      subject: "",
      limit: 5,
      conversationIndex: 0,
      showCreate: true,
      response_message_uuid: "",
      items: [],
      count: 0,
      currentPage: 1,
      responded_message_id: "",
      found_page: 0
    };
  },
  methods: {
    gotoMessage(message_uuid){
      if(this.inbox){
        this.$emit("gotoMessage",message_uuid);
      }else{
        if(this.user_uuid != "")
        {
          this.$router.push({
              name: 'MessageViewWithUserUuid',
              params: {
                user_uuid: this.user_uuid,
                message_uuid: this.message_uuid,
              },
            });
        }else{
          if(this.functionbox_uuid != ""){
            this.$router.push({
              name: 'MessageViewWithFunctionBoxUuid2',
              params: {
                functionbox_uuid: this.functionbox_uuid,
                message_uuid: this.message_uuid,
              },
            });
          }else{
            this.$router.push({
              name: 'MessageView',
              params: {
                message_uuid: this.message_uuid
              },
            });
          }
        }
      }
    },
    closeConversation()
    {
      this.$emit('closeConversation');
    },
    createdResponse(item) {
      this.items.unshift(item);
      this.showCreate = false;
    },
    refreshList() {
      this.getResponses();
    },
    cancelReply() {
      this.showCreate = false;
    },
    respondedMessage() {
      this.getResponses();
    },
    isMe(item) {
      if(this.functionbox_uuid != "")
      {
        return this.functionbox_uuid == item.user_uuid;
      }
      if(this.user_uuid != "")
      {
        return this.user_uuid == item.user_uuid;
      }
      return this.user.information.user_uuid == item.user_uuid;
    },
    getResponses: function() {
      let self = this;
      this.error = false;
      this.opened = false;
      this.$http
        .post(this.user.hostname + "/message/conversation/list", {
          message_uuid: this.message_uuid,
          functionbox_uuid: this.functionbox_uuid,
          user_uuid: this.user_uuid,
          page: this.currentPage,
          limit: this.limit,
        })
        .then((response) => {
          self.conversationIndex++;
          self.subject = response.data.subject;
          self.response_message_uuid = response.data.response_message_uuid;
          self.items = response.data.items;
          if (self.items.length > 1) {
            self.showCreate = false;
          }
          self.count = response.data.count;
          if(self.firstRun){
            setTimeout(() => {
              self.$scrollTo("#message_" + self.message_uuid.replace(":", ""));
            }, 1000);
          }
          self.firstRun = false;
          self.loading = false;
        })
        .catch(() => {
          self.loading = false;
        });
    }
  },
  computed: {
    direktLink() {
      return this.user.hostname.replace("/server/rest","") + "/conversation?user_uuid=&functionbox_uuid=&message_uuid=" + this.message_uuid;
    },
    conversationKey() {
      return "conversation_" + this.conversationIndex;
    },
  },
  mounted() {
    this.getResponses();
  },
};
</script>
<style lang="css"></style>
